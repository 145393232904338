import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { NavLink as Link } from "react-router-dom";
import styled from 'styled-components';

const NavContainer = styled.div`
    padding: 30px 0 30px 0;
    padding-top: ${props => props.theme.isMobile ? "0" : "30px"};
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 30;
`;

const NavLinks = styled.nav`
    display: flex;
    flex-direction: row;
    gap: ${props => props.theme.isMobile ? "3em" : "10em"};
`;

const HashLinkContainer = styled.div`
    padding: 10px;

    &:hover a{
        text-decoration: line-through;
    }
`;

const StyledNavLink = styled(Link)`
    color: #ffffff;
    font-family:"Apercu bold", Calibri, sans-serif; 
    font-size-adjust:0.508; 
    font-weight:bold; 
    font-style:normal;
    text-transform: uppercase;
    line-height: 100%;
    font-size: 10px;
    word-spacing: 108px;
    letter-spacing: 1px;
    text-align: center;
    text-decoration: none;
`;


const Nav = ({ message }) => {

    return(
        <NavContainer>
            <NavLinks>
                <HashLinkContainer>
                <StyledNavLink to="/" activeStyle>
                    PORTFOLIO
                </StyledNavLink>
                </HashLinkContainer>
                <HashLinkContainer>
                <StyledNavLink to="/work" activeStyle>
                    WORK
                </StyledNavLink>
                </HashLinkContainer>
                <HashLinkContainer>
                <StyledNavLink to="/contact" activeStyle>
                    CONTACT
                </StyledNavLink>
                </HashLinkContainer>
            </NavLinks>
        </NavContainer>
    );
};

export default Nav;