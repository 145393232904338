import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled, { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Routes, Route }
    from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

import logo from './logo.svg';
import "./fonts/Apercu_Regular.otf";
import "./fonts/Apercu_Bold.otf";

import Contact from './pages/contact';
import Work from './pages/work';
import Grid from './components/Grid';
import GwenHeader from './components/GwenHeader';
import Nav from './components/Nav';

const AppWrapper = styled.div`
  background-color: #1D1D15;
`;

library.add(fab, faPlay);


function App() {
  const isMobile = useMediaQuery({ query: '(max-width: 744px)' });
  const isTablet = useMediaQuery({ minWidth: 744, maxWidth: 1024 });
  const isSmallDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1280 });
  const isDesktop = useMediaQuery({ minWidth: 1280 });

  const media = {
    isDesktop,
    isMobile,
    isSmallDesktop,
    isTablet,
  };

  const fullTheme = { ...media };

  return (
    <ThemeProvider theme={fullTheme}>
      <AppWrapper>
        <Router>
          <Nav/>
          <GwenHeader/>
          <Routes>
            <Route exact path='/' element={<Grid/>} />
            <Route path='/work' element={<Work />} />
            <Route path='/contact' element={<Contact />} />
          </Routes>
        </Router>
      </AppWrapper>
    </ThemeProvider>
  );
}

export default App;
