import React from 'react';
import styled from 'styled-components';

const VIDEO_WIDTH = 1920;
const VIDEO_HEIGHT = 1080;

const VideoBackgroundWrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
`;

const YoutubeVideo = styled.iframe`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 177.78vh;
    height: 100vh;
    transform: translate(-50%, -50%);
`;

const VideoBackground = () => {
    return (
        <VideoBackgroundWrapper>
            <YoutubeVideo
                width={VIDEO_WIDTH}
                height={VIDEO_HEIGHT}
                src="https://www.youtube.com/embed/0SkkfojQCCo?autoplay=1&controls=0&mute=1&loop=1&modestbranding=1&showinfo=0&start=50&playlist=0SkkfojQCCo&enablejsapi=1&&widgetid=3"
                title="Gwen Roger freestyle"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"/>
        </VideoBackgroundWrapper>
    );
};

export default VideoBackground;