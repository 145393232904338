import React, { useState } from 'react';
import styled, { css } from 'styled-components';

const ContentContainer = styled.div`
    clear: both;
    padding: 30px 0 0 0;
    position: relative;
    text-align: center;
    z-index: 10;
    margin: 0 auto;
    max-width: 1180px;
    height: 1000px;
    /*padding-top: 100%;*/
    font-family: 'Apercu bold';

    ${props => props.theme.isTablet &&
        css`
            max-width: 744px;
        `}

    ${props => props.theme.isMobile &&
        css`
            max-width: 475px;
        `}
`;

const ProjectThumb = styled.div`
    display: inline-block;
    margin: 0 0 0 0;
    position: absolute;
    text-align: center;
    vertical-align: bottom;

    & .hover {
        cursor: pointer;
        text-decoration: none;
    }

    & .nohover {
        text-decoration: none;
    }

    &:hover .cardimgcrop img {
       /* opacity: .1;
        filter: alpha(opacity=00);*/
        transform: scale(1.5);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        transition: transform .5s ease;
        /*width: calc(100% * 1.5);
        height: auto;*/
    }
    
    
    
    /*&:hover .thumb_title{
        filter: alpha(opacity=100);
        opacity: 1;
    }*/
`;

const Item5238426 = styled(ProjectThumb)`
    left: 100px;
    top: 60px;
    z-index: 8;
`;

const Item5238526 = styled(ProjectThumb)`
    left: 280px;
    top: 250px;
    z-index: 9;
`;

const Item5238544 = styled(ProjectThumb)`
    left: 0px;
    top: 410px;
    z-index: 8;
`;

const Item5238601 = styled(ProjectThumb)`
    left: 20px;
    top: 730px;
    z-index: 9;
`;

const Item5238656 = styled(ProjectThumb)`
    left: 840px;
    top: 300px;
    z-index: 10;

    ${props => props.theme.isTablet &&
        css`
            left: 415px;
            top: 1120px;
        `}
    ${props => props.theme.isMobile &&
        css`
            left: 0px;
            top: 2040px;
        `}
`;


const Item5238745 = styled(ProjectThumb)`
    left: 280px;
    top: 690px;
    z-index: 9;
`;

const Item5238794 = styled(ProjectThumb)`
    left: 480px;
    top: 130px;
    z-index: 9;

    ${props => props.theme.isMobile &&
        css`
            left: 175px;
            top: 1030px;
        `}
`;

const Item5238804 = styled(ProjectThumb)`
    left: 476px;
    top: 505px;
    z-index: 10;

    ${props => props.theme.isMobile &&
        css`
            left: 217px;
            top: 1817px;
        `}
`;

const Item5238836 = styled(ProjectThumb)`
    left: 610px;
    top: 390px;
    z-index: 9;

    ${props => props.theme.isMobile &&
        css`
            left: 10px;
            top: 1645px;
        `}
`;

const Item5256568 = styled(ProjectThumb)`
    left: 740px;
    top: 70px;
    z-index: 10;

    ${props => props.theme.isTablet &&
    css`
        left: 330px;
        top: 875px;
    `}

    ${props => props.theme.isMobile &&
        css`
            left: 0px;
            top: 1080px;
        `}
`;

const Item5256586 = styled(ProjectThumb)`
    left: 960px;
    top: 15px;
    z-index: 9;

    ${props => props.theme.isTablet &&
        css`
            left: 0px;
            top: 1030px;
        `}

    ${props => props.theme.isMobile &&
        css`
            left: 160px;
            top: 2115px;
        `}
`;

const Item5256598 = styled(ProjectThumb)`
    left: 890px;
    top: 370px;
    z-index: 8;

    ${props => props.theme.isTablet &&
        css`
            left: 490px;
            top: 810px;
        `}

    ${props => props.theme.isMobile &&
        css`
            left: 90px;
            top: 1312px;
        `}
`;

const CardImgCrop = styled.div`
    overflow: hidden;
    margin-bottom: 15px;
    position: relative;

    &.notitle_notag {
        margin-bottom: 22px;
    }
`;

const CardImgCropImage = styled.img`
    opacity: 1;
        filter: alpha(opacity=100);
    
        -webkit-transition: opacity .3s ease-in-out;
        -moz-transition: opacity .3s ease-in-out;
        -o-transition: opacity .3s ease-in-out;
        -ms-transition: opacity .3s ease-in-out;
        transition: opacity .3s ease-in-out;
`;

const LoaderHolder = styled.div`
    display: none;
    background: white;
    position: absolute;
    text-align: center;
    top: 8px; left: 8px;
    z-index: 3;
`;

const ThumbTitle = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    width: 100%;
    left: 0px;
    margin-top:-7px;
    padding: 0;
    top:40%;
    z-index: 99;
    position: absolute;

    filter: alpha(opacity=100);
    opacity: 0;

    -webkit-transition: opacity .3s ease-in-out;
    -moz-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    -ms-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;

    margin: 0 0 22px 0;
`;

const ThumbTitleText = styled.span`
    color: #000;
    font-family:"Apercu bold", Calibri, sans-serif; font-size-adjust:0.508; font-weight:bold; font-style:normal;
    font-size: 10px;
    padding-bottom: 1px;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    letter-spacing:1px;
`;
 

function HoverableImageWrapper({children, href, id, name, rel}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const className = isHovered ? 'hover' : 'nohover';

  return (
    <a 
        href={href} 
        onMouseOver={handleMouseOver}  
        onMouseOut={handleMouseOut} 
        className={className} 
        id={id} 
        rel={rel}
        name={name} 
        onFocus={(e) => e.target.blur()}>
      {children}
    </a>
  );
}


const Grid = ({ message }) => {

    const handleMouseOver = () => {
        this.className='hover';
    };

    const handleMouseOut = () => {
        this.className='nohover';
    };

    return(
        <ContentContainer>
            <div id="page_1">
                <Item5238426 className="project_thumb" id="item_5238426" name="5238426" page="1" spot="-1">
                    <div id="pr_contain_item_5238426">
                        <HoverableImageWrapper href="/" id="p5238426" rel="history" name="gwenroger">
                            <LoaderHolder className="loader_holder " id="load_5238426">
                                <img src="/_gfx/loadingAnim.gif" />
                            </LoaderHolder>
                            <CardImgCrop className="cardimgcrop" id="cardthumb_5238426" name="">
                                <CardImgCropImage src="./assets/headshot9.JPG" border="0" rel="testprt_1363961720.jpg" width="250" height="290"/>
                            </CardImgCrop>
                            <ThumbTitle className="thumb_title notag"><ThumbTitleText className="text">Emma</ThumbTitleText>&nbsp;</ThumbTitle>
                        </HoverableImageWrapper>
                    </div>
                </Item5238426>
                <Item5238526 className="project_thumb" id="item_5238526" name="5238526" page="1" spot="-1">
                    <div id="pr_contain_item_5238526">
                        <HoverableImageWrapper href="/" id="p5238526" rel="history" name="gwenroger">
                            <LoaderHolder className="loader_holder " id="load_5238526">
                                <img src="/_gfx/loadingAnim.gif" />
                            </LoaderHolder>
                            <CardImgCrop className="cardimgcrop " id="cardthumb_5238526" name="">
                                <CardImgCropImage src="./assets/headshot16.JPG" border="0" rel="testprt_1363962328.jpg" width="160" height="200" />
                            </CardImgCrop>
                            <ThumbTitle className="thumb_title notag"><ThumbTitleText className="text">Birth</ThumbTitleText>&nbsp;</ThumbTitle>
                        </HoverableImageWrapper>
                    </div>
                </Item5238526>
                <Item5238794 className="project_thumb" id="item_5238794" name="5238794" page="1" spot="-1">
                    <div id="pr_contain_item_5238794">
                        <HoverableImageWrapper href="/" id="p5238794" rel="history" name="gwenroger">
                            <LoaderHolder className="loader_holder " id="load_5238794">
                                <img src="/_gfx/loadingAnim.gif"/>
                            </LoaderHolder>
                            <CardImgCrop className="cardimgcrop " id="cardthumb_5238794" name="">
                                <CardImgCropImage src="./assets/headshot10.JPG" border="0" rel="testprt_1363964550.jpg" width="250" height="300"/>
                            </CardImgCrop>
                            <ThumbTitle className="thumb_title notag"><ThumbTitleText className="text">Into the Wild</ThumbTitleText>&nbsp;</ThumbTitle>
                        </HoverableImageWrapper>
                    </div>
                </Item5238794>
                <Item5256568 className="project_thumb" id="item_5256568" name="5256568" page="1" spot="-1">
                    <div id="pr_contain_item_5256568">
                        <HoverableImageWrapper href="/" id="p5256568" rel="history" name="gwenroger">
                            <LoaderHolder className="loader_holder " id="load_5256568">
                                <img src="/_gfx/loadingAnim.gif"/>
                            </LoaderHolder>
                            <CardImgCrop className="cardimgcrop " id="cardthumb_5256568" name="">
                                <CardImgCropImage src="./assets/headshot5.JPG" border="0" rel="testprt_1364203367.jpg" width="140" height="172"/>
                            </CardImgCrop>
                            <ThumbTitle className="thumb_title notag"><ThumbTitleText className="text">The Man</ThumbTitleText>&nbsp;</ThumbTitle>
                        </HoverableImageWrapper>
                    </div>
                </Item5256568>
                <Item5256586 className="project_thumb" id="item_5256586" name="5256586" page="1" spot="-1">
                    <div id="pr_contain_item_5256586">
                        <HoverableImageWrapper href="/" id="p5256586" rel="history" name="gwenroger">
                            <LoaderHolder className="loader_holder " id="load_5256586">
                                <img src="/_gfx/loadingAnim.gif"/>
                            </LoaderHolder>
                            <CardImgCrop className="cardimgcrop " id="cardthumb_5256586" name="">
                                <CardImgCropImage src="./assets/headshot3.JPG" border="0" rel="testprt_1364203466.jpg" width="210" height="300"/>
                            </CardImgCrop>
                            <ThumbTitle className="thumb_title notag"><ThumbTitleText className="text">Illum</ThumbTitleText>&nbsp;</ThumbTitle>
                        </HoverableImageWrapper>
                    </div>
                </Item5256586>
                <Item5238544 className="project_thumb" id="item_5238544" name="5238544" page="1" spot="-1">
                    <div id="pr_contain_item_5238544">
                        <HoverableImageWrapper href="/" id="p5238544" rel="history" name="gwenroger">
                            <LoaderHolder className="loader_holder " id="load_5238544"><img src="/_gfx/loadingAnim.gif"/></LoaderHolder>
                            <CardImgCrop className="cardimgcrop " id="cardthumb_5238544" name="">
                                <CardImgCropImage src="./assets/headshot6.JPG" border="0" rel="testprt_1363962634.jpg" width="320" height="400"/>
                            </CardImgCrop>
                            <ThumbTitle className="thumb_title notag"><ThumbTitleText className="text">Pilgrim</ThumbTitleText>&nbsp;</ThumbTitle>
                        </HoverableImageWrapper>
                    </div>
                </Item5238544>
                <Item5238804 className="project_thumb" id="item_5238804" name="5238804" page="1" spot="-1">
                    <div id="pr_contain_item_5238804">
                        <HoverableImageWrapper href="/" id="p5238804" rel="history" name="gwenroger">
                            <LoaderHolder className="loader_holder " id="load_5238804"><img src="/_gfx/loadingAnim.gif"/></LoaderHolder>
                            <CardImgCrop className="cardimgcrop " id="cardthumb_5238804" name="">
                                <CardImgCropImage src="./assets/headshot12.JPG" border="0" rel="testprt_1363964612.jpg" width="170" height="270"/>
                            </CardImgCrop>
                            <ThumbTitle className="thumb_title notag"><ThumbTitleText className="text">I keep my lips sealed</ThumbTitleText>&nbsp;</ThumbTitle>
                        </HoverableImageWrapper>
                    </div>
                </Item5238804>
                <Item5238836 className="project_thumb" id="item_5238836" name="5238836" page="1" spot="-1">
                    <div id="pr_contain_item_5238836">
                        <HoverableImageWrapper href="/" id="p5238836" rel="history" name="gwenroger">
                            <LoaderHolder className="loader_holder " id="load_5238836"><img src="/_gfx/loadingAnim.gif"/></LoaderHolder>
                            <CardImgCrop className="cardimgcrop " id="cardthumb_5238836" name="">
                                <CardImgCropImage src="./assets/headshot17.JPG" border="0" rel="testprt_1363964875.jpg" width="200" height="220"/>
                            </CardImgCrop>
                            <ThumbTitle className="thumb_title notag"><ThumbTitleText className="text">Nicotine Girl</ThumbTitleText>&nbsp;</ThumbTitle>
                        </HoverableImageWrapper>
                    </div>
                </Item5238836>
                <Item5238656 className="project_thumb" id="item_5238656" name="5238656" page="1" spot="-1">
                    <div id="pr_contain_item_5238656">
                        <HoverableImageWrapper href="/" id="p5238656" rel="history" name="gwenroger">
                            <LoaderHolder className="loader_holder " id="load_5238656"><img src="/_gfx/loadingAnim.gif"/></LoaderHolder>
                            <CardImgCrop className="cardimgcrop " id="cardthumb_5238656" name="">
                                <CardImgCropImage src="./assets/headshot18.JPG" border="0" rel="testprt_1363963543.jpg" width="100" height="150"/>
                            </CardImgCrop>
                            <ThumbTitle className="thumb_title notag"><ThumbTitleText className="text">Metal Girl</ThumbTitleText>&nbsp;</ThumbTitle>
                        </HoverableImageWrapper>
                    </div>
                </Item5238656>
                <Item5256598 className="project_thumb" id="item_5256598" name="5256598" page="1" spot="-1">
                    <div id="pr_contain_item_5256598">
                        <HoverableImageWrapper href="/" id="p5256598" rel="history" name="gwenroger">
                            <LoaderHolder className="loader_holder " id="load_5256598"><img src="/_gfx/loadingAnim.gif"/></LoaderHolder>
                            <CardImgCrop className="cardimgcrop " id="cardthumb_5256598" name="">
                                <CardImgCropImage src="./assets/headshot1.JPG" border="0" rel="testprt_1364203938.jpg" width="220" height="330"/>
                            </CardImgCrop>
                            <ThumbTitle className="thumb_title notag"><ThumbTitleText className="text">Let it Shine</ThumbTitleText>&nbsp;</ThumbTitle>
                        </HoverableImageWrapper>
                    </div>
                </Item5256598>
                <Item5238601 className="project_thumb" id="item_5238601" name="5238601" page="1" spot="-1">
                    <div id="pr_contain_item_5238601">
                        <HoverableImageWrapper href="/" id="p5238601" rel="history" name="gwenroger">
                            <LoaderHolder className="loader_holder " id="load_5238601"><img src="/_gfx/loadingAnim.gif"/></LoaderHolder>
                            <CardImgCrop className="cardimgcrop " id="cardthumb_5238601" name="">
                                <CardImgCropImage src="./assets/headshot14.JPG" border="0" rel="testprt_1363963004.jpg" width="220" height="276"/>
                            </CardImgCrop>
                            <ThumbTitle className="thumb_title notag"><ThumbTitleText className="text">Dark Angel</ThumbTitleText>&nbsp;</ThumbTitle>
                        </HoverableImageWrapper>
                    </div>
                </Item5238601>
                <Item5238745 className="project_thumb" id="item_5238745" name="5238745" page="1" spot="-1">
                    <div id="pr_contain_item_5238745">
                        <HoverableImageWrapper href="/" id="p5238745" rel="history" name="gwenroger">
                            <LoaderHolder className="loader_holder " id="load_5238745"><img src="/_gfx/loadingAnim.gif"/></LoaderHolder>
                            <CardImgCrop className="cardimgcrop " id="cardthumb_5238745" name="">
                                <CardImgCropImage src="./assets/headshot2.JPG" border="0" rel="testprt_1363964251.jpg" width="150" height="200"/>
                            </CardImgCrop>
                            <ThumbTitle className="thumb_title notag"><ThumbTitleText className="text">Bodega Life</ThumbTitleText>&nbsp;</ThumbTitle>
                        </HoverableImageWrapper>
                    </div>
                </Item5238745>
                <div className="pagecontainer" id="item_5387758" name="5387758" page="0" spot="12"></div>
            </div>
        </ContentContainer>
    );
};

export default Grid;