import React from 'react';
import styled from 'styled-components';

const ContentContainer = styled.div`
    position: absolute;
    top: 30px;
    z-index: 50;
`;

const HeaderText = styled.div`
    color: #FFFFFF;
    font-family:"Apercu bold", Calibri, sans-serif; font-size-adjust:0.508; font-weight:bold; font-style:normal;
    font-size: 64px;
    padding-bottom: 1px;
    white-space: nowrap;
    text-align: left;
    text-transform: uppercase;
    letter-spacing:1px;
`;

const GwenHeader = ({ message }) => {

    return(
        <ContentContainer>
            <HeaderText>GW</HeaderText>
            <HeaderText>-N</HeaderText>
            <HeaderText>ROG</HeaderText>
            <HeaderText>-R</HeaderText>
        </ContentContainer>
    );
};

export default GwenHeader;