import React from 'react';
import styled, { css, useTheme } from 'styled-components';

import ContactSection from '../components/ContactSection';
import VideoBackground from '../components/VideoBackground';

const ContentContainer = styled.div`
    clear: both;
    position: relative;
    text-align: center;
    font-family: 'Apercu bold';
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => props.theme.isMobile &&
      css`
        height: 100vh;
      `}
`;

const Contact = () => {
  const theme = useTheme();
	return (
		<ContentContainer>
      <ContactSection/>
			{theme.isMobile ? null : <VideoBackground/>}
		</ContentContainer>
	);
};

export default Contact;