import React, { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled, { css, keyframes, useTheme } from 'styled-components';
import Tilt from 'react-parallax-tilt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Data = [
    {
        title: 'MUSIC VIDEOS',
        jobs: [{title: 'BAD BUNNY – WHERE SHE GOES - Dancer - Choreo: Tom Hayes - Prod: Stillz', youtubeId:'bef8QLNHubw'}, 
        {title: 'YA LEVIS – TAKALA - Dancer - Choreo: Laure Courtellemont', youtubeId:'pdnVfMDu6VU'}, 
        {title: 'YA LEVIS - NAKATI - Dancer - Choreo: Laure Courtellemont', youtubeId:'L4MWKGstaHk'}, 
        {title: 'YA LEVIS - NAKATI CONCEPT - Dancer - Choreo: Laure Courtellemont & Sheryl Murakami', youtubeId:''},
        {title: 'IZA - SEM FILTRO - Dancer - Choreo: Laure Courtellemont', youtubeId:'fWVTtwI4Rco'}, 
        {title: 'IZA FT CIARA, MAJOR LAZOR - Asst Choreographer - Choreo: Laure Courtellemont', youtubeId:'c0Dl8JbNzCo'}, 
        {title: 'DAVID MICHIGAN - WHINE FOR ME - Dancer - Choreo: Laure Courtellemont - Dir: A.Flores', youtubeId:'n2etQgr0IKk'},
        {title: 'YA LEVIS Ft. YXNG BANE - CANDY SHOP - Dancer - Choreo: Laure Courtellemont', youtubeId:'flTeKEGVYp4'},
        {title: 'ANNA - CHA - Dancer - Choreo: Laure Courtellemont', youtubeId:'9NJeYE_aFxw'},
        {title: 'DEXTA DAPS - SLIPPERY AND WET - Dancer - Choreo: Laure Courtellemont', youtubeId:''},
        {title: 'PIX’L - HOLD UP - Dancer - Choreo: Laure Courtellemont', youtubeId:'zxUR-8EufkE'},
        {title: 'DANA VAUGHNS – KINGDOM - Dancer - Choreo: Laure Courtellemont', youtubeId:'CkwGcThGy48'},
        {title: 'EVA SHAW - MIDNIGHT STRANGERS - Dancer - Brock Newman', youtubeId:'3SmBH4Skzqc'},
        {title: 'GHITA - ON MY MIND - Dancer - Choreo: Laure Courtellemont', youtubeId:''},
        {title: 'DAYA LUZ - VIRAR O GAME - Dancer - Choreo: Laure Courtellemont', youtubeId:'FDpgdvu3320'},
        {title: 'DOROTHY FT ANGEL HAZE - FREEDOM - Featured - Dir: Lisa Bonnet', youtubeId:''},
        {title: 'SHAKTI MOHAN CONCEPT VIDEO - Dancer & Asst Choreo - Choreo: Sir Charles', youtubeId:''},
        {title: 'CALI4NIA JONES FT LEX LU - Dancer', youtubeId:''},
        {title: 'JOVI ROCKWELL - Dancer - Choreo: Lorenzo Hanna', youtubeId:''},
        {title: 'DJ AVEDON FT TANK - Featured', youtubeId:'i9Wsn0bJ1R0'},
        {title: 'PRISCILLA - SO SE EU FOSSE MALUCA - Dancer', youtubeId:''},
        {title: 'TAHRA - BLANAK KHAWY 4K - Dancer - Choreo: Lorenzo Hanna', youtubeId:'FakyTeGLuBQ'}
        ]
    },
    {
        title: 'LIVE PERFORMANCES',
        jobs: [{title: 'JLO APPLE MUSIC LIVE – Orpheum Theatre - Ast Ch - Choreo: Sheryl Murakami C.Director: NappyTabs', youtubeId:''}, {title: 'OPENING SHOW FINALE CONCACAF – VEGAS - Dancer - Choreo: Laure Courtellemont', youtubeId:''},
        {title: 'OPENING SHOW FINALE CONCACAF – LA - Dancer - Choreo: Laure Courtellemont', youtubeId:''},
        {title: 'YA LEVIS CONCERT - LA CIGALE, PARIS - Dancer - Choreo: Laure Courtellemont', youtubeId:''},
        {title: 'KANYE WEST SUNDAY SERVICE - CALABASAS  - Choreo: R.J. Banks, F. Bentley, F. Chambers', youtubeId:''},
        {title: 'DAVID MICHIGAN PROMO - TIME SQUARE, NYC - Dancer - Choreo: Laure Courtellemont', youtubeId:''},
        {title: 'YA LEVIS CONCERT - M. MOULIN ROUGE, PARIS - Dancer - Choreo: Laure Courtellemont', youtubeId:''}
        ]
    },
    {
        title: 'TV',
        jobs: [{title: 'NOWRUZ TV SHOW - Dancer', youtubeId:''}]
    },
    {
        title: 'MODELING',
        jobs: [{title: 'VIS MA VUE', youtubeId:''},
        {title: 'CAMELIAS AND CURLS', youtubeId:''},
        {title: 'CONNIE TAO DESIGNS', youtubeId:''}
        ]
    },
    {
        title: 'TRAINING',
        jobs: [
        {title: 'JOB DANCE REEL', youtubeId:'', vimeoId: '711208074'},
        {title: 'SPECIALTY: DANCEHALL', youtubeId:'', vimeoId: '868109773'},
        {title: 'ASSISTANT CHOREOGRAPHER LAURE COURTELLEMONT', youtubeId:''}, 
        {title: 'ASSISTANT SHERYL MURAKAMI', youtubeId:'', vimeoId: '787402354'},
        {title: 'ASSISTANT MARIE POPPINS – REVEAL CONVENTION', youtubeId:''},
        {title: 'SNAPSQUAD (HIP HOP) BY JOSH WILLIAMS (AKA TAIWAN WILLIAMS)', youtubeId:''},
        {title: 'AISHA FRANCIS PROGRAMS', youtubeId:''},
        {title: 'OMEGAFLOW FLOORWORK BY RANDI FREITAS', youtubeId:''},
        {title: 'DANCEHALL FUNK BY LORENZO HANNA', youtubeId:''},
        {title: 'DANCE REEL', youtubeId:'', vimeoId: '865437693'}
        ]
    }
  ];

const ContentContainer = styled.div`
    clear: both;
    position: relative;
    text-align: center;
    font-family: 'Apercu bold';
    display: flex;
    flex-wrap: wrap;
    background-color: #1D1D15;
`;

const LeftSection = styled.div`
    background-color: #1D1D15;
    flex: 50%;
    height: 100vh;
    display: flex;
    align-items: end;
`;

const LeftSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-right: 1px solid #ffffff;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    width: calc(100% - 20px);
    height: calc(100% - 70px);
    position: relative;

    ${props => props.theme.isMobile &&
        css`
        height: 100%;
    `}
`;

const RightSection = styled.div`
    flex: 50%;
    height: 100vh;
    position: relative;

    ${props => props.theme.isMobile &&
        css`
        width: 100%;
    `}
`;

const RightSectionWrapper = styled.div`
    position: relative;
    top: 70px;
    padding-left: 20px;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-left: 1px solid #ffffff;

    ${props => props.theme.isMobile &&
        css`
        padding-left: 0px;
        height: 100%;
        top: 0px;
    `}
`;

const Row = styled.div`
    height: 20%;
    border: solid 1px rgba(207,207,197);
    cursor: pointer;
`;


const RowContent = styled.div`
    height: 100%;
    padding: 0 20px;
    background-color: #1D1D15;
    color: #ffffff;
    font-family:"Apercu", Calibri, sans-serif;
    font-size: 52px;
    display: flex;
    align-items: center;
    justify-content: start;
    text-align: left;

    ${props => props.theme.isMobile &&
        css`
        font-size: 45px;
    `}
`;

const Placeholder = styled.div`
    border: 1px solid #ffffff;
    width: 190px;
    height: 240px;
    position: absolute;
`;

const Placeholder1 = styled(Placeholder)`
    left: 40%;
    top: 35%;
`;

const Placeholder2 = styled(Placeholder)`
    left: 19%;
    top: 22%;
`;

const Placeholder3 = styled(Placeholder)`
    left: 26%;
    top: 43%;
`;

const Placeholder4 = styled(Placeholder)`
    right: 24%;
    top: 50%;
`;

const slide = (top, left) => {
    return(
    keyframes`
    100% { left: ${ left }%; top: ${ top }%; }
`);
}

const arrowAnimation = () => {
    return(
        keyframes`
        0% { opacity: 1}
        100% { opacity: 0; transform: translate(-10px, -10px); }
        `
    );

}

const slideIn = keyframes`
    100% { left: 0; }
`

const PlaceHolderContent = styled(Placeholder)`
    position: absolute;
    left: -600px;
    width: 600px;
    height: 370px;
    background: blue;
    -webkit-animation: ${slide} 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: ${slide} 0.5s forwards;
    animation-delay: 2s;
`;

const ArrowIndicatorMobile = styled.div`
    position: absolute;
    left: 50%;
    width: 40px;
    height: 40px;
    bottom: 50px;
    margin: -20px 0 0 -20px;
    -webkit-transform: rotate(45deg);
    border-left: none;
    border-top: none;
    border-right: 3px #FF6700 solid;
    border-bottom: 3px #FF6700 solid;

    &:before {
        position: absolute;
        left: 50%;
    }

    &:before {
        content: '';
        width: 20px;
        height: 20px;
        top: 50%;
        margin: -10px 0 0 -10px;
        border-left: none;
        border-top: none;
        border-right: 1px #FF6700 solid;
        border-bottom: 1px #FF6700 solid;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-name: ${arrowAnimation};
    }
`;

const YoutubeVideo = styled.div`
    overflow: hidden;
    padding-bottom: 56.25%;
    width: 100%;
    top: 0px;
    position:absolute;
    /*position: relative;*/
    height: 0;
    left: -120%;
    -webkit-animation: ${ ({top, left}) => slide(top, left) } 0.9s forwards;
    -webkit-animation-delay: 1s;
    animation: ${ ({top, left}) => slide(top, left) } 0.9s forwards;
    animation-delay: 1s;
`;

const VimeoVideo = styled(YoutubeVideo)``;

const YoutubeIframe = styled.iframe`
    left: 0;
    top: 0;
    height: 60%;
    width: 60%;
    position: absolute;
    border: 1px solid #ffffff;

    ${props => props.theme.isMobile &&
        css`
            height: 80%;
            width: 80%;
        `}
`;

const VimeoIframe = styled(YoutubeIframe)``;

const Dropdown = styled.div`
    /*height: ${ ({height}) => height };*/
    height: ${ ({height}) => height === 'full' ?  '100%' : '0px' };
    color: #ffffff;
    width: auto;
    display: flex;
    border: solid 1px rgba(207,207,197);
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    transition: height ease 0.9s;
    text-align: left;
    padding: ${ ({padding}) => padding }px 0px ${ ({padding}) => padding }px ${ ({padding}) => padding }px;

    ${props => props.theme.isMobile &&
        css`
            height: auto;
            max-height: ${ ({height}) => height === 'full' ?  '1000px' : '0px' };
            transition: max-height ease-in-out 1s;
    `}
`;

const JobDescr = styled.div`
  cursor: pointer;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    margin-left: 15px;
`;

const YoutubeEmbed = ({ embedId = 'mM2O73Sgqw0' }) => {
    const shouldShowArrowInd = useMediaQuery({ query: '(max-width: 894px)' });
    const maxLeftRandom = shouldShowArrowInd ? 30 : 40;

    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
    }

    return (
        <YoutubeVideo left={getRandomInt(0,maxLeftRandom)} top={getRandomInt(30,60)}>
            <YoutubeIframe width="560" height="315" src={`https://www.youtube.com/embed/${embedId}`} title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
        </YoutubeVideo>
    );
};

const VimeoEmbed = ({ embedId = '859937164' }) => {
    const shouldShowArrowInd = useMediaQuery({ query: '(max-width: 894px)' });
    const maxLeftRandom = shouldShowArrowInd ? 30 : 40;

    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
    }

    return (
        <VimeoVideo left={getRandomInt(0,maxLeftRandom)} top={getRandomInt(30,60)}>
            <VimeoIframe width="560" height="315" src={`https://player.vimeo.com/video/${embedId}`} title="" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></VimeoIframe>
        </VimeoVideo>
    );
};

const Work = () => {
    const [selectedSectionIndex, setSelectedSectionIndex] = useState(false);
    const [selectedJobIndex, setSelectedJobIndex] = useState(false);

    const shouldShowArrowInd = useMediaQuery({ query: '(max-width: 894px)' });

    const bottomSectionRef = useRef(null);
    const topSectionRef = useRef(null);

    const toggleSection = index => {
        if (selectedSectionIndex === index) {
        return setSelectedSectionIndex(null);
        }

        setSelectedSectionIndex(index);
    };

    const showJobPreviewVideo = index => {
        setSelectedJobIndex(index);
        scrollToTop();
    }

    const scrollToCategories = () => {
        bottomSectionRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    const scrollToTop = () => {
        topSectionRef.current?.scrollIntoView({behavior: 'smooth'});
    };

	return (
		<ContentContainer>
			<LeftSection>
                <LeftSectionWrapper ref={topSectionRef}>
                    <Placeholder1></Placeholder1>
                    <Placeholder2></Placeholder2>
                    <Placeholder3></Placeholder3>
                    <Placeholder4></Placeholder4>
                    {(!!selectedSectionIndex || selectedSectionIndex === 0) && (!!selectedJobIndex || selectedJobIndex === 0) && Data[selectedSectionIndex].jobs[selectedJobIndex].youtubeId &&
                        <YoutubeEmbed embedId={Data[selectedSectionIndex].jobs[selectedJobIndex].youtubeId}/>
                    }
                    {(!!selectedSectionIndex || selectedSectionIndex === 0) && (!!selectedJobIndex || selectedJobIndex === 0) && Data[selectedSectionIndex].jobs[selectedJobIndex].vimeoId &&
                        <VimeoEmbed embedId={Data[selectedSectionIndex].jobs[selectedJobIndex].vimeoId}/>
                    }
                    {shouldShowArrowInd && <ArrowIndicatorMobile onClick={scrollToCategories} />}
                </LeftSectionWrapper>
            </LeftSection>
            <RightSection>
                <RightSectionWrapper ref={bottomSectionRef}>
                {Data.map((item, index) => {
                    return (
                        <>
                            <Row key={index} onClick={() => toggleSection(index)}>
                                <Tilt perspective={500}
                                    glareEnable={true}
                                    glareMaxOpacity={0.45}
                                    scale={1.02}
                                    style={{ height: '100%' }}> 
                                    <RowContent>{item.title}</RowContent>
                                </Tilt>
                            </Row>
                                <Dropdown height={selectedSectionIndex === index ? 'full' : '0'} padding={selectedSectionIndex === index ? '20' : '0'}>
                                    {item.jobs.map((job, i) => {
                                        if(selectedSectionIndex === index) {
                                            return (
                                                <JobDescr key={i} onClick={() => showJobPreviewVideo(i)}>
                                                    {job.title}
                                                    { (Data[selectedSectionIndex].jobs[i].youtubeId || Data[selectedSectionIndex].jobs[i].vimeoId) &&
                                                        <StyledFontAwesomeIcon icon="fa-solid fa-play" size="md" />
                                                    }
                                                </JobDescr>
                                            );
                                        } else{
                                            return null;
                                        }
                                    })}
                                </Dropdown>
                        </>
                    );
                })}
                </RightSectionWrapper>
            </RightSection>
		</ContentContainer>
	);
};

export default Work;