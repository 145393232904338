import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ContentContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 50;
    backdrop-filter: invert(80%) blur(10px);
    width: 50%;
    height: 50%;
    padding: 35px;
    gap: 20px;
    box-shadow:  10px 10px 60px #14140f,
             -10px -10px 60px #26261b;

    ${props => props.theme.isMobile &&
        css`
            width: 70%;
            background-color: rgba(0,0,0, 0.8);
            backdrop-filter: none;
    `}

`;

const HeaderText = styled.div`
    color: #FFFFFF;
    font-family:"Apercu bold", Calibri, sans-serif; font-size-adjust:0.508; font-weight:bold; font-style:normal;
    font-size: 22px;
    padding-bottom: 1px;
    text-align: left;
    letter-spacing:1px;
`;

const RegText = styled(HeaderText)`
    font-family:"Apercu", Calibri, sans-serif; font-size-adjust:0.508; font-weight:bold; font-style:normal;
    font-size: 15px;
`;

const SocialMediaContainer = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 20px;
    color: #ffffff;
`;

const Row = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: center;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: #ffffff;
`;

const Styledlink = styled.a`
    color: #ffffff;
    margin-left: 15px;
`;

const ContactSection = () => {

    return(
        <ContentContainer>
            <HeaderText>Location: <RegText>US and Europe</RegText></HeaderText>
            <HeaderText>Email: <RegText>rogergwendoline01@gmail.com</RegText></HeaderText>
            <HeaderText>US Dance representation: <RegText>Go 2 Talent Agency</RegText></HeaderText>
            <HeaderText>Social Media:</HeaderText>
            <SocialMediaContainer>
                <Row><StyledFontAwesomeIcon icon="fa-brands fa-instagram" size="lg" /><Styledlink href="https://www.instagram.com/gwenrdf/" target="_blank">@gwenrdf</Styledlink></Row>
                <Row><StyledFontAwesomeIcon icon="fa-brands fa-youtube" size="lg" /><Styledlink href="https://www.youtube.com/@gwen5152" target="_blank">@gwen5152</Styledlink></Row>
            </SocialMediaContainer>
        </ContentContainer>
    );
};

export default ContactSection;